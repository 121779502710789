.DrawerToggle {
    padding: 10px;
    cursor: pointer;

    div {
        width: 25px;
        height: 4px;
        background-color: #5c5c4e;
        margin: 3px 0;
        border-radius: 2px;
    }

    @media (min-width: 720px) {
        display: none;
    }
}


