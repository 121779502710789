.toolbar{
  height: 90px;
  background: #fff;
  transition: all .2s ease-in-out;
  z-index: 9;

  @media (min-width: 720px) {
    box-shadow: 0px 2px 10px rgba(0,0,0, .2);
  }

  .wrapper{
    max-width: 1126px;
    height: 100%;
    margin: 0 auto;
    padding: 0 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    position: relative;
    z-index: 2;
  }

  .Logo{
    margin-bottom: 0;
  }

  .menu{
    text-align: right;

    & > a{
      text-decoration: none !important;
      transition: all .2s ease-in-out;

      &.active,
      &:hover{
        text-decoration: none;
        padding-top: 10px;
        padding-top: 10px;
      }
    }
  }
}

@media (max-width: 720px) {
    .DesktopOnly {
        display: none;
    }
}
