.Input {
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
}

.simnao{
    display: flex;
}

.Label {
    font-weight: bold;
    display: block;
    margin-bottom: 2px;
    font-size: 0.9em;
    text-align: left;
}

.simnao label{
    width: 80%;
}

.InputElement {
    font-weight: bold;
    padding: 10px;
    margin-bottom: 32px;
    font-size: 0.9em;
    text-align: center;
    box-shadow: 0 0 0 0;
    background-color: #F7F7F7;
    border: none;
    border-bottom: solid 2px #0D2020;
    height: 56px;
    width: 100%;
    border-radius: 0.2em;
}

.simnao input{
    width: 20%;
}

.InputElement:focus {
    outline: none;
    box-shadow: #444bc9 0px 0px 0px 1px;
    border-color: #444bc9;
    border-bottom: solid 2px #444bc9;
}

.Invalid {
    outline: none;
    box-shadow: #c9444d 0px 0px 0px 1px;
    border-color: #c9444d;
    border-bottom: solid 2px #c9444d;
}
