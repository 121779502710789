.NavigationItems {
    height: 100%;
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-flow: column;
    align-items: center;
    flex-flow: row;

    .Buttonsignup{
        @media (max-width: 720px) {
            display: none;
        }
    }

    .MenuMobile{
        width: 100%;
        padding: 0 0;

        @media (min-width: 720px) {
            display: none;
        }
    }

    .MenuDesktop{
        @media (max-width: 720px) {
            display: none;
        }
    }
}
