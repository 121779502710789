.FindButton {
    background-color:rgb(170, 196, 1);
    border-radius: 4px;
    padding: 8px 18px;
    text-align: center;
    border: none;
    color: #fff;
    outline: none;
    cursor: pointer;
    font-size: 14px;
    letter-spacing: .2px;
    transition: all .2s ease-in-out;

    &:hover{
        background-color: lighten(#123321, 20%);
    }

    &.ghost{
        font-weight: bold;
        border: solid 2px #123321;
        background: transparent;
        color: #123321;

        &:hover{
            border: solid 2px lighten(#123321, 20%);
            background-color: lighten(#123321, 20%);
            color: #fff;
        }
    }
}

.Button:disabled {
    color: #ccc;
    cursor: not-allowed;
}

.Success {
    color: #5C9210;
}

.Danger {
    color: #944317;
}

.Link {
    background: none!important;
    border: none;
    text-decoration: none;
    color: inherit;
    cursor: pointer;
}

.Linkexcluir {
    background: none!important;
    border: none;
    text-decoration: none;
    color: #d94325;
    cursor: pointer;
}
