.SideDrawer {
    width: 280px;
    max-width: 70%;
    height: 100%;
    left: 0;
    top: 0;
    padding: 32px 16px;
    background-color: white;
    box-sizing: border-box;
    transition: transform 0.3s ease-out;
    z-index: 200;
    position: fixed;

    .Logo {
        height: 70px;
    }

    .NavigationItems{
        margin-top: 30px;
        flex-flow: column;
        align-items: inherit;

        li{

            .buttton-icon-user{
                display: none;
            }

            a{
                transition: all .2s ease-in-out;

                &:hover{
                    padding-left: 30px;
                }

                &:after{
                    display: none;
                }
            }

            &.toggle-button{
                margin-left: 0;
            }
        }
    }
}

@media (min-width: 720px) {
    .SideDrawer {
        display: none;
    }
}

.Open {
    transform: translateX(0);
}

.Close {
    transform: translateX(-100%);
}
