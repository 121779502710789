.Logo {
  height: 100%;
  padding: 4px;
  box-sizing: border-box;
  border-radius: 5px;
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;

  img {
    height: 100%;
    margin-right: 5px;
  }

  &,
  & .text{
    text-decoration: none;
    color: #58594B;
  }

  .text{
    width: 60px;
    font-style: inherit;
    font-weight: bold;
    line-height: 1;
    font-size: 1.0em;
    text-align: left;
    vertical-align: middle;
  }

  &:hover,
  &:focus,
  &:active{
    &,
    & .text{
      text-decoration: none;
    }
  }

  &:hover{
    &,
    & .text{
      color: darken(#92DE36, 20%);
    }
  }
}

/*
@media (max-width: 499px) {
  .Logo .texto {
    display: none;
  }
}
*/
