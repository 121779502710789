.NavigationItems {
  & > li{
    &.margin-left{
      margin-left: 20px;
    }
  }
  & > button{
    &.margin-left{
      margin-left: 20px;
    }
  }
}

.NavigationItem {
  height: 100%;
  width: auto;
  margin: 0;
  display: flex;
  align-items: center;
  text-align: left;
  font: Bold 16px/19px Lato;
  font-weight: bold;
  letter-spacing: 0px;
  color: #0D2020;

  & > a {
    color: inherit;
    height: 100%;
    padding: 20px;
    position: relative;

    &.menu-fx{
      &:after{
        width: 0;
        height: 2px;
        background-color: #0D2020;
        bottom: 8px;
        left: 20px;
        content: '';
        transition: all .2s ease-in-out;
        position: absolute;
      }
    }

    &,
    &:hover,
    &:focus,
    &:active,
    &.active {
      text-decoration: none;
    }

    &:hover,
    &:focus,
    &:active,
    &.active {
      &:after{
        width: calc(100% - 40px);
      }
    }

    &.active {
      color: #94cf1a;

      &:after{
        background-color: #94cf1a;
      }
    }
  }

  &.toggle-button{
    margin-left: 20px;
    position: relative;

    .buttton-icon-user{
      width: 60px;
      padding: 10px 0px;
      background-color: #123321;
      border: none;
      border-radius: 4px;

      .icon-user{
        width: 26px;
      }
    }
  }

  a {
    width: 100%;
    padding: 10px 20px;
    display: block;
    box-sizing: border-box;
    letter-spacing: 0px;
    text-decoration: none;
    text-align: left;
    font: Bold 16px/19px 'Lato';
    color: #565648;
    cursor: pointer;
  }
}

.toggleMenu{
  background: #fff;
  border-radius: 4px;
  box-shadow: 0px 2px 4px #00000029;
  top: 80px;
  right: 0;
  position: absolute;

  &:after{
    width: 0;
    height: 0;
    top: -10px;
    right: 20px;
    display: block;
    border-top: solid 0 transparent;
    border-right: solid 10px transparent;
    border-bottom: solid 10px #fff;
    border-left: solid 10px transparent;
    content: '';
    position: absolute;
  }

  & > ul{
    min-width: 160px;
    margin: 0;
    padding: 0;

    li{
      a{
        padding: 10px 20px;
      }
    }

    button{
      a{
        padding: 10px 20px;
      }
    }
  }
}
