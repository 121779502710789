.Content {
}

.formularios{
    padding-top: 10px;
}

.titulo{
    font-size: 1.7em;
    text-align: center;
}
@media (min-width: 340px) {
    .titulo {
        padding: 0 20% 0 20%;
    }
}

.Form-center {
    margin: 20px auto;
    width: 100%;
    text-align: center;
    padding: 10px;
    box-sizing: border-box;
}

.image-upload>input,
.image-upload>button {
    display: none;
  }
.image-upload>label>img {
    width: 60px;
    height: 100%;
}


.Infowindow h4{
    line-height: 1;
    margin-top: 5px;
}

.Infowindow h5{
    line-height: 1;
    margin: 5px;
}

.controlesmapa {
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    align-content: space-between;
    justify-content: space-between;
}

.control_autocomplete{
    width: 80%;
    height: 100%;
}

.control_btcentralizar{
    width: 20%;
    height: 100%;
    text-align: center;
    display: table-cell;
    vertical-align: middle;
}

.btcentericon {
    margin:0px;
    padding:0px;
    border:0;
    background:transparent;
    white-space:nowrap;
    width:auto;
    overflow:visible;
}
